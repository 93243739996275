<template>
  <!-- 案例展示 -->
  <div class="w-layout caselist">
    <div class="wrap-main">
      <div class="case-nav">
        <h3>成功案例</h3>
        <div class="case-sub-nav">
          <ul>
            <li
              v-for="(item, index) in titleList"
              @click="selCase(index)"
              :class="selNum === index ? 'hover' : ''"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="case-list">
        <ul>
          <li v-for="(caseinfo, index) in caseList" :key="index">
            <a href="javascript:void(0)" class="img-box">
              <img class="bgimg" src="../assets/img/bg.png" />
              <img
                class="case-img"
                :src="require('@/assets/img/case/' + caseinfo.caseimg)"
              />
              <div class="up-graybox">
                <span class="bgbox"></span>
                <p class="stit">
                  <span class="line linet"></span>
                  {{ caseinfo.title }}
                  <br />
                  {{ caseinfo.type }}
                  <span class="line lineb"></span>
                </p>              
              </div>
            </a>
            <div class="cont-box">
              <p class="box-title">
                <a>{{ caseinfo.title }}</a>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export const defaulList = [
  {
    caseimg: "floor.jpg",
    title: "玖固环氧地坪",
    type: "企业官网",
    url: "/"
  },
  {
    caseimg: "trave.jpg",
    title: "休闲周边旅游",
    type: "企业官网",
    url: "/"
  },
  {
    caseimg: "build.jpg",
    title: "建筑项目承包",
    type: "企业官网",
    url: "/"
  }
];

export default {
  data() {
    return {
      selNum: 0,
      titleList: [
        {
          title: "企业官网",
        },
        {
          title: "外贸网站",
        },
        {
          title: "跨境电商",
        },
        {
          title: "店铺网站",
        }
      ],
      caseList: defaulList,
    };
  },
  methods: {
    selCase(index) {
      this.selNum = index;
      if (index == 0) {
        this.caseList = defaulList;
      } else if (index == 1) {
        this.caseList = [
          {
            caseimg: "print.jpg",
            title: "手持打印设备",
            type: "外贸网站",
            url: "/",
          },
          {
            caseimg: "dog.jpg",
            title: "家庭宠物狗粮",
            type: "外贸网站",
            url: "/",
          },
          {
            caseimg: "light.jpg",
            title: "室内照明灯具",
            type: "外贸网站",
            url: "/",
          }
        ];
      } else if (index == 2) {
        this.caseList = [
          {
            caseimg: "clothes.jpg",
            title: "时尚女装B2C",
            type: "跨境电商",
            url: "/",
          },
          {
            caseimg: "bed.jpg",
            title: "沙发枕头B2C",
            type: "跨境电商",
            url: "/",
          },
          {
            caseimg: "lamp.jpg",
            title: "台灯照明B2C",
            type: "跨境电商",
            url: "/",
          }
        ];
      } else if (index == 3) {
        this.caseList = [
          {
            caseimg: "cooffee.jpg",
            title: "咖啡时光-咖啡店",
            type: "店铺网站",
            url: "/",
          },
          {
            caseimg: "drink.jpg",
            title: "奶茶时光-奶茶店",
            type: "店铺网站",
            url: "/",
          },
          {
            caseimg: "cake.jpg",
            title: "莫卡蛋糕-蛋糕店",
            type: "店铺网站",
            url: "/",
          }
        ];
      }
    },
  },
  computed: {
    selNum() {
      return this.selNum;
    },
  },
};
</script>
