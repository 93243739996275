<template>
  <layout-header :images="images" />
  <layout-product />
  <layout-footer />
</template>

<script>
import product from "../components/product.vue";
export default {
  components: {
    product,
  },
  data() {
    return {
      images: [
        { imageUrl: "banner03.jpg" },
      ],
    };
  },
};
</script>
