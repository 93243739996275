import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/style/style.css';

import header from './components/header.vue';
import footer from './components/footer.vue';
import product from './components/product.vue';

const app =createApp(App)
app.use(router)

app.component("layout-header",header)
app.component("layout-footer",footer)
app.component("layout-product",product)

app.mount('#app')

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
})

// 禁用右键
document.addEventListener('contextmenu', function(e) {
  e.preventDefault();
});
 
// 禁用F12、保存
document.addEventListener('keydown', function(e) {
  if ((event.ctrlKey || event.metaKey) && event.key === 's' || e.key === 'F12') {
    e.preventDefault();
  }
});

