<template>
  <div class="main">
    <div class="the-item">
      <div class="gray-container well">
        <a v-if="isMobile" @click="pay('h5')" class="btn_com"
          ><i class="icon icon-download sm white"></i>&nbsp;立即下载</a
        >
        <a v-else @click="pay('pc')" class="btn_com"
          ><i class="icon icon-download sm white"></i>&nbsp;立即下载</a
        >
      </div>
      <div class="gray-container well">
        <div class="cont">
          单页官网模板专为科技、企服公司，提供了一个强大的平台来增强您的品牌影响力、吸引客户展示您的服务。&nbsp;
          <div><br /></div>
          <div><b>主要特色</b></div>
          <div>
            <ul>
              <li>HTML5和CSS3编码</li>
              <li>搜索引擎友好</li>
               <li>自适应</li>
              <li>
                兼容<span
                  style="
                    color: rgb(84, 84, 84);
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 16px;
                  "
                  >所有现代浏览器</span
                >
              </li>
              <li>SEO 友好结构</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gray-container well">
        <div class="file-tree">
          <file-node
            v-for="file in files"
            :key="file.id"
            :file="file"
          ></file-node>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileNode from "../components/file.vue";

export default {
  components: {
    FileNode,
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
    },
  },
  methods: {
    pay(device) {
      const axios = require("axios");
      axios
        .post("/api/front/alipay/" + device, this.formData)
        .then((response) => {
          const newWindow = window.open("", "");
          newWindow.document.write(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  mounted() {
    document.title = "单页自适应企业官网模板--巨橙模板";
  },
  data() {
    return {
      formData: {
        tradeNo: "001",
        subject: "单页自适应企业官网模板",
        totalAmount: "0.01",
        body: "单页自适应企业官网模板，适用企服、软件行业。",
      },
      files: [
        {
          id: 1,
          name: "单页自适应企业官网模板",
          type: "zip",
          switch: true,
          children: [
            {
              id: 2,
              name: "css",
              type: "folder",
              children: [
                { id: 21, name: "bootstrap.min.css", type: "css" },
                { id: 22, name: "style.css", type: "css" },
              ],
            },
            {
              id: 3,
              name: "images",
              type: "folder",
              children: [
                { id: 31, name: "chengxu.png", type: "img" },
                { id: 32, name: "gongshang.png", type: "img" },
                { id: 33, name: "gupiao.png", type: "img" },
                { id: 34, name: "jizhang.png", type: "img" },
                { id: 35, name: "touzi.png", type: "img" },
                { id: 36, name: "topbg.png", type: "img" },
                { id: 37, name: "web.png", type: "img" },
                { id: 38, name: "wechat.png", type: "img" },
              ],
            },
            {
              id: 4,
              name: "js",
              type: "folder",
              children: [{ id: 41, name: "bootstrap.min.js", type: "js" }],
            },
            {
              id: 5,
              name: "favicon.ico",
              type: "img",
            },
            {
              id: 5,
              name: "index.html",
              type: "html",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.icon-folder::before {
  content: url("../assets/img/folder.png");
  padding-right: 5px;
}
.icon-css::before {
  content: url("../assets/img/css.png");
  padding-right: 5px;
}
.icon-js::before {
  content: url("../assets/img/js.png");
  padding-right: 5px;
}
.icon-html::before {
  content: url("../assets/img/html.png");
  padding-right: 5px;
}
.icon-img::before {
  content: url("../assets/img/img.png");
  padding-right: 5px;
}
.icon-file::before {
  content: url("../assets/img/file.png");
  padding-right: 5px;
}
.icon-zip::before {
  content: url("../assets/img/zip.png");
  padding-right: 5px;
}

.main {
  width: 800px;
  margin: 0 auto;
  clear: both;
  position: relative;
}
@media (min-width: 769px) {
  div.the-item {
    float: left;
    width: 530px;
  }
}

div.the-item {
  margin: 0 0 10px 0;
  padding-top: 5px;
}
* {
  word-wrap: break-word;
  font-family: 微软雅黑;
  font-size: 14px;
  outline: none;
}
div.the-item .gray-container {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 15px;
}
div.the-item .gray-container .cont,
div.the-item .gray-container .cont * {
  font-size: 14px;
  line-height: 22px;
}
.well {
  background: #f5f8fa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.12);
}
.btn_com {
  display: inline-block;
  width: 94px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  text-shadow: none;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  border-radius: 18px;
  background-color: #00c7e1;
  box-shadow: 0 2px 4px 0 rgba(0, 188, 212, 0.28);
  border: solid 3px rgba(255, 255, 255, 0.4);
}
</style>
